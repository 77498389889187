<template>
	<div class="container">
		<div class="nei_box">
			<el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
				<el-form-item label="企业所属技术领域" prop="type">
					<el-select v-model="ruleForm.type" placeholder="请选择"><el-option v-for="item in arrs" :key="item" :label="item" :value="item"></el-option></el-select>
				</el-form-item>
				<el-form-item label="企业注册年限" prop="name">
					<!--  :max="10" -->
					<el-input-number v-model="ruleForm.year" @change="handleChange" :min="0" label="描述文字"></el-input-number>
				</el-form-item>
				<el-form-item label="I类知识产权数量" prop="name">
					<!--  :max="10" -->
					<el-input-number v-model="ruleForm.li" @change="handleChange" :min="0" label="描述文字"></el-input-number>
				</el-form-item>
				<el-form-item label="II类知识产权数量" prop="name">
					<!--  :max="10" -->
					<el-input-number v-model="ruleForm.lli" @change="handleChange" :min="0" label="描述文字"></el-input-number>
				</el-form-item>
				<el-form-item label="科技人员占总人数比例" prop="region">
					<el-select v-model="ruleForm.ren" placeholder="请选择">
						<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="年产值" prop="region">
					<el-select v-model="ruleForm.nian" placeholder="请选择">
						<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="有无发生重大安全事故" prop="resource">
					<el-radio-group v-model="ruleForm.you">
						<el-radio label="有"></el-radio>
						<el-radio label="否"></el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item class="button_box">
					<el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
					<el-button @click="resetForm('ruleForm')">取消</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import { bili, fen } from '../../common/js/api';
export default {
	name: 'qiyecx',
	data() {
		return {
			arrs: ['电子信息', '生物与新医药', '航空材料', '高新技术服务', '新能源与节能', '资源与环境', '先进制造与自动化', '新材料', '其他'],
			num: 1,
			ruleForm: {
				name: '', //公司名字
				type: '', //领域
				year: '', //年限
				li: '', //一级
				lli: '', //二级
				ren: '', //人员比例
				nian: '', //人员比例
				you: '' //
			},
			options: [],

			bai_bi: []
		};
	},
	created() {
		this.ruleForm.name = this.$route.query.name;
		this.bai_fens();
	},
	methods: {
		handleChange(value) {
			console.log(value);
		},
		bai_fens() {
			bili({})
				.then(res => {
					console.log('成果领域', res.data);
					this.options = res.data;
				})
				.catch(err => {});
		},
		submitForm(formName) {
			console.log('this.ruleForm', this.ruleForm);
			fen({
				data: this.ruleForm
			})
				.then(res => {
					console.log(res);
					this.options = res.data;
					this.$message(res.msg);
					this.$router.push({
						path: '/qiyecx'
			  });
				})
				.catch(err => {});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		}
	}
};
</script>

<style scoped lang="less">
/deep/.el-icon-minus {
	line-height: 57px;
}
/deep/.el-icon-plus {
	line-height: 57px;
}
/deep/.el-input-number__decrease {
	top: 2px;
	box-sizing: border-box;
	width: 100px;
	height: 57px;
}
/deep/.el-input-number__increase {
	top: 2px;
	box-sizing: border-box;
	right: -419px;
	width: 100px;
	height: 57px;
}
/deep/.el-input {
	width: 600px;
}
.el-radio-group {
	line-height: 70px;
}
.el-select {
	width: 640px;
}
.button_box {
	margin-left: 45% !important;
	padding-bottom: 30px;
}
/deep/.el-checkbox-group {
	width: 688px;
	margin-left: 400px;
}
/deep/.el-form-item {
	/*margin-left: 1%;*/
}
/deep/.el-form-item__label {
	width: 400px !important;
	height: 60px;
	line-height: 60px;
}
/deep/.el-input__inner {
	height: 60px;
	line-height: 60px;
}
/deep/.el-form {
	padding-top: 50px;
}
.container {
	background-color: #f8f9fa;
	width: 100%;
	height: auto;
	overflow: hidden;
	.nei_box {
		width: 1200px;
		margin: 0 auto;
		background-color: #fff;
	}
}
@media screen and (max-width: 1200px) {
	/deep/.el-form-item__label{
		width: 16rem !important;
		font-size: 1.4rem;
	}
	/deep/.el-input__inner{
		font-size: 1.5rem;
	}
	/deep/.el-radio__label{
		font-size: 1.5rem;
	}
	.button_box{
		margin-left: 25% !important;
	}
	/deep/.el-button{
		font-size: 1.6rem;
	}
}
</style>
